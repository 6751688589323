body {
  font-family: "Open Sans", sans-serif;
  font-size: 0.95rem;
  background: #000;
  color: #eee;
}

.header {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header img {
  width: 80%;
  height: 80%;
  max-width: 500px;
}

h1 {
  font-size: 1.5rem;
  text-align: center;
}

table {
  max-width: 900px;
  margin-bottom: 4rem;
}
table thead {
  display: none;
}
table tbody, table tr, table th, table td {
  display: block;
}
table td {
  padding: 0.25rem 0;
}
table td:first-child {
  font-weight: 600;
}
table td a {
  color: #eee;
  text-decoration: none;
}
table td a:hover {
  filter: brightness(1.2);
  cursor: pointer;
  text-decoration: underline;
}
table tr {
  padding: 1rem;
  border-bottom: 1px solid #808080;
}
table tr td:first-child {
  font-weight: 600;
}
table tr td.datetime .long {
  display: none;
}
table tr:last-child {
  border-bottom: none;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 4rem;
  width: 100%;
  background-color: #808080;
  display: flex;
  justify-content: center;
}
footer img {
  height: 4rem;
  width: 4rem;
}

@media only screen and (min-width: 768px) {
  table {
    border-collapse: collapse;
    margin: 2rem auto 4rem auto;
  }
  table thead {
    display: table-header-group;
    border-bottom: 1px solid #a0a0a0;
  }
  table thead tr {
    font-size: 0.8em;
  }
  table thead tr th {
    text-transform: uppercase;
    text-align: left;
    padding: 0 0 0.5rem 1rem;
  }
  table thead tr th:first-child {
    width: 40%;
  }
  table tbody {
    display: table-row-group;
  }
  table tr {
    display: table-row;
  }
  table tr th, table tr td {
    display: table-cell;
  }
  table tr td {
    padding: 1rem;
  }
  table tr td.datetime .short {
    display: none;
  }
  table tr td.datetime .long {
    display: inline;
  }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
  /* portrait phones */
}
